import { ProjectCategory } from '~/enums';

import practiMain from './assets/practi.jpg';
import practiMainMobile from './assets/practi-mobile.jpg';
import practiApp1 from './assets/practi-app-1.jpg';
import practiApp1Mobile from './assets/practi-app-1-mobile.jpg';
import practiApp2 from './assets/practi-app-2.jpg';
import practiApp2Mobile from './assets/practi-app-2-mobile.jpg';
import practiApp3 from './assets/practi-app-3.jpg';
import practiApp3Mobile from './assets/practi-app-3-mobile.jpg';
import practiApp4 from './assets/practi-app-4.jpg';
import practiPlatform from './assets/practi-platform.jpg';
import practiPlatformMobile from './assets/practi-platform-mobile.jpg';
import routes from '~/routes';

const practiGeneralInfo = {
  name: 'Practi White Label',
  nameShort: 'Practi',
  category: ProjectCategory.RESTAURANT_MANAGEMENT,
  summary: 'Website builder for restaurant owners',
  img: practiMain,
  imgMobile: practiMainMobile,
  projectPageUrl: routes.CASE_STUDIES_PRACTI,
  description: '',
};

const practiCaseStudy = {
  generalInfo: practiGeneralInfo,
  client: {
    descriptionShort:
      'Practi is a powerful tool for restaurants that covers almost every possible management task – from creating websites, through managing orders and deliveries, to data analysis.',
    descriptionLong:
      'Practi has recently been acquired and is now a part of Just Eat – a British online delivery food service valued at over 6 billion USD.',
  },
  scope: {
    types: ['Design', 'Development'],
    title: 'Our Work',
    descriptionShort:
      'Practi White Label is a comprehensive food delivery platform that stands toe to toe with Uber Eats or Wolt.',
    descriptionLong:
      '<p>An easy to use system we have built for restaurant owners allows them to create <b>professional websites</b> with integrated online orders solutions in a snap, and run them with no effort at all. A <b>step-by-step creator</b> guides users through adding menu, pictures and contact information, and customizing  such elements as logo or color palette. The websites can integrate with other tools from the Practi ecosystem automatically.</p><p>Thanks to a mobile-friendly interface and an integrated payment and delivery system, ordering food through websites created with While Label takes seconds. Fast-pace of White Label’s development was especially vital due to the COVID-19 outbreak, since many restaurants had to <b>shift to delivery-first or delivery-only strategy</b>. We made helping them our primary goal and rolled out a reliable solution as fast as it was possible.</p>',
  },
  platforms: {
    label: 'Platform',
    types: ['Web (mobile-responsive)'],
  },
  platform: {
    img: practiPlatform,
    imgMobile: practiPlatformMobile,
    techStack: [
      'TypeScript',
      'React',
      'Node.js',
      'Kotlin',
      'Natural Language Processing',
    ],
  },
  tldr:
    'We have designed and developed an advanced website builder for restaurants, equipped with an integrated food delivery system. We finished the project ahead of schedule to help restaurants deal with COVID-19 crisis.',
  appImgs: [
    { img: practiApp1, imgMobile: practiApp1Mobile },
    { img: practiApp2, imgMobile: practiApp2Mobile },
    { img: practiApp3, imgMobile: practiApp3Mobile },
    { img: practiApp4 },
  ],
};

export default practiCaseStudy;
