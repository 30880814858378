import React from 'react';
import { PageProps } from 'gatsby';
import omit from 'lodash/omit';
import classNames from 'classnames';

import data from '~/data/case-studies/practi';
import { ProjectDTO } from '~/types';
import Layout from '~/components/Layout';
import ProjectHeader from '~/components/ProjectHeader';
import ClientDescription, {
  ClientDescriptionData,
} from '~/components/ClientDescription';
import ProjectExcerpt, {
  ProjectExcerptData,
} from '~/components/ProjectExcerpt';
import EstimationCTA from '~/components/EstimationCTA';
import ScopeDescription, {
  ScopeDescriptionData,
} from '~/components/ScopeDescription';
import Image from '~/components/Image';
import Keywords from '~/components/Keywords';
import OtherProjects from '../components/OtherProjects';

import styles from './Practi.module.scss';

const Practi: React.FC<PageProps> = ({ location }) => {
  const {
    generalInfo,
    client,
    scope,
    tldr,
    appImgs,
    platforms,
    platform,
  } = data;

  const projectHeaderData: ProjectDTO = {
    ...omit(generalInfo, 'nameShort'),
    img: data.generalInfo.img,
  };

  const clientDescriptionData: ClientDescriptionData = {
    textShort: client.descriptionShort,
    textLong: client.descriptionLong,
    scope: scope.types,
    techStack: platform.techStack,
  };

  const projectExcerptData: ProjectExcerptData = { text: tldr };

  const scopeDescriptionData: ScopeDescriptionData = {
    textShort: scope.descriptionShort,
    textLong: scope.descriptionLong,
  };

  return (
    <Layout invertNavigation>
      <header className={styles.header}>
        <ProjectHeader
          data={projectHeaderData}
          imgContainerClass={styles.headerImgContainer}
        />
      </header>
      <article className={styles.article}>
        <ClientDescription data={clientDescriptionData} />
      </article>
      <article className={styles.platform}>
        <div className={styles.platformImgContainer}>
          <Image
            img={platform.img}
            imgMobile={platform.imgMobile}
            alt={generalInfo.name}
            className={styles.platformImg}
          />
        </div>
        <Keywords label={platforms.label} keywords={platforms.types} />
      </article>

      <article className={styles.article}>
        <ProjectExcerpt data={projectExcerptData} />
      </article>
      <article className={styles.article}>
        <ScopeDescription data={scopeDescriptionData} />
      </article>
      <article className={styles.imgContainer}>
        {appImgs.map(({ img, imgMobile }, index) => (
          <Image
            key={index}
            img={img}
            imgMobile={imgMobile}
            alt={generalInfo.name}
            pictureClassName={styles.picture}
            className={classNames(styles.img, {
              [styles.lastImage]: index === appImgs.length - 1,
            })}
          />
        ))}
      </article>
      <article className={styles.cta}>
        <EstimationCTA />
      </article>
      <article>
        <OtherProjects curretPageUrl={location.pathname} />
      </article>
    </Layout>
  );
};

export default Practi;
